






















import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  setup(_, { root }) {
    const login = ref('');
    const password = ref('');
    const loading = ref(false);

    const loginBtnClicked = async () => {
      loading.value = true;
      const response = await fetch('https://inventory.unirail.kz/api/v1/authorization', {
        body: JSON.stringify({ login: login.value, password: password.value }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      loading.value = false;

      if (data.token === '') {
        alert('Неправильный логин или пароль');
        login.value = '';
        password.value = '';
        return;
      }

      localStorage.setItem('token', data.token);
      await root.$router.push('/');
    };

    return {
      login,
      password,
      loading,
      loginBtnClicked,
    };
  },
});
